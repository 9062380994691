<template>
  <vs-card class="questionnare-table">
    <div slot="header" class="flex items-center justify-between w-full">
      <h3> Consent Form Questions </h3>
      <vs-button
          @click="createQuestionnaireHandler"
          icon-pack="feather"
          icon="icon-plus"> Create Questions </vs-button>
    </div>


    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
       <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <div>
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item,index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="question">Questions</vs-th>
          <vs-th sort-key="isEnabled">Enabled status</vs-th>
          <vs-th sort-key="isEnabled">Mandatory</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th>Action</vs-th>
        </template>


        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].question">
                <button @click="editDetailHandler(data[indextr]._id)" class="block lg:pointer-events-none">
                  {{ data[indextr].question }}
                </button>
            </vs-td>
            <vs-td :data="data[indextr].isEnabled">{{ data[indextr].isEnabled ? 'Yes' : 'No' }}</vs-td>
            <vs-td :data="data[indextr].is_mandatory">{{ data[indextr].is_mandatory ? 'Yes' : 'No' }}</vs-td>
            <vs-td
              :data="data[indextr].createdAt"
            >{{ moment(data[indextr].createdAt).format('DD/MM/YYYY') }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vx-tooltip text="View/Edit Details">
                <vs-button
                  style="float: left;"
                  type="border"
                  size="small"
                  @click="editDetailHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-edit"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Delete">
                <vs-button
                  style="float: left;"
                  type="border"
                  size="small"
                  @click="deleteQuestionnaireHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-delete"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>
            </vs-td>

          </vs-tr>
        </template>
      </vs-table>

      <div class="flex items-center justify-between my-4 mx-4 lg:mx-6">
        <span class="text-sm">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
          ? dataTableParams.page * dataTableParams.limit
          : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <template v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          />
        </template>
      </div>

    </div>
  </vs-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect
  },
  props: {
    createRoute: {
      type: String,
      default: "QuestionnaireCreate"
    }
  },
  data() {
    return {
      noDataText: "Loading...",
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "asc",
        page: 1,
        limit: 25
      },
      users: [],
      serverResponded: false,
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      awaitingSearch: null
    };
  },
  methods: {
    ...mapActions("questionnaire", [
      "fetchQuestionnaire",
      "deleteQuestionnaire"
    ]),
    moment(date) {
      return moment(date);
    },
    getQuestionnaireList() {
      let self = this;
      this.fetchQuestionnaire(self.dataTableParams).then(res => {
        console.log("Hello");
        console.log(res);
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.serverResponded = true;
        self.noDataText = "No Data Found";
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getQuestionnaireList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getQuestionnaireList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getQuestionnaireList();
    },
    editDetailHandler(id) {
      this.$router.push({
        name: "CMSConsentQuestionnaire",
        params: { id: id }
      });
    },
    createQuestionnaireHandler() {
      this.$router.push({ name: this.createRoute });
    },
    async deleteQuestionnaireHandler(id) {
      let self = this;
      await this.deleteQuestionnaire(id)
        .then(res => {
          self.$vs.notify({
            title: "Question Deleted",
            text: "Question deleted Successfully.",
            color: "success"
          });
          this.getQuestionnaireList();
        })
        .catch(err => {
          self.$vs.notify({
            title: "Failed",
            text: "Failed to delete questionnaire. Try again!",
            color: "danger"
          });
          if (err.status === 422) {
            self.mapServerErrors(self, err, "basic");
          }
        });
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getQuestionnaireList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getQuestionnaireList();
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.getQuestionnaireList();
  }
};
</script>


<style lang="scss">
.questionnare-table {
  .con-select {
    width: 100px;
  }

  .vs {
    &-con {
      &-tbody {
        border: none !important;
      }
    }

    &-card--content {
      padding: 0;
      border-top: 1px solid #f8f8f8 !important;
    }

    &-select {
      &--input,
      &--item {
        font-family: inherit !important;
      }
    }

    &-table {
        &--tbody-table {
          min-width: unset !important;
        }
        &--header {
          padding: 0 15px;
        }
        td {
          &:first-child {
            button {
              pointer-events: none;
              appearance: none;
              border: none;
              background: none;
              text-align: left;
              font-family: inherit;
              color: inherit;
              font-size: 1rem;
              font-family: inherit !important;
            }
          }
        }
    }
  }

  @media screen and (max-width: 600px) {
    .vs {
      &-table {
        &--header {
          display: none;
        }

        &--thead {
          border: none;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }

        tr {
          &:not(:last-child) {
            border-bottom: 1px solid #f8f8f8;
            display: block;
          }
        }

        td {
          border: none;
          display: flex;
          text-align: left;
          padding: 12px 15px !important;

          &:not(:first-child) {
            display: none;
          }
          & button {
            pointer-events: auto !important;
            position: relative;
            width: 90%;
          }
          & span {
            display: block;
            flex: 1;
            width: 100%;
            position: relative;

            &::after {
              content: '';
              font-weight: bold;
              text-transform: uppercase;
              background-size: contain;
              background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNNy4zMyAyNGwtMi44My0yLjgyOSA5LjMzOS05LjE3NS05LjMzOS05LjE2NyAyLjgzLTIuODI5IDEyLjE3IDExLjk5NnoiLz48L3N2Zz4=");
              width: 10px;
              height: 10px;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

  }
}
</style>
